/*设备样式*/
.device-item {
    display: flex;
    font-size: 1.4rem;
}

/*设备图片*/
.device-pic {
    display: flex;
    flex: 1;
    /*justify-content: center;*/
    align-items: center;
    /*border: 1px solid dodgerblue;*/
}

/*设备开关*/
.device-power {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.device-power i {
    font-size: 2.5rem;
}

/*设备是否打开*/
.device-open {
    margin-top: 1rem ;
    font-size: 1.3rem;
}

/*设备名称*/
.device-intro {
    flex: 3;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

/*设备名称*/
.device-name {
    flex: 1;
}

/*设备状态信息*/
.device-status {
    display: flex;
    flex: 3;
    flex-direction: row;
    align-items: flex-end;
}

/*设备数值*/
.device-num {
    font-size: 4rem;
    margin-right: 0.5rem;
}

/*设备单位*/
.device-unit {
    margin-right: 0.5rem;
    margin-bottom: 0.7rem;
    font-size: 1.2rem;
}

/*设备标签*/
.device-label {
    margin-bottom: 0.7rem;
    margin-right: 0.5rem;
}

/*设备底部三个小图标*/
.device-tag {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 5px;
}

.power_on{
    width: 4rem;
    height: 4rem;
    background-color: #80d8ff;
    border-radius:3rem;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap:wrap;
    box-shadow:0 0 0.3rem 1rem rgba(7, 179, 243, 0.2);
}
.power_off{
    width: 4rem;
    height: 4rem;
    /*background-color: #cccccc;*/
    border-radius:3rem;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap:wrap;
    /*box-shadow:0 0 0.3rem 1rem rgba(204, 204, 204 , 0.5);*/
}

