.wind-container{
    padding:2rem 0 0;
    background:#f8f8f8;
}

.wind-container>div{
    padding-bottom: 2rem;
}

.wind-container .block-title{
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
}

.block-title-2{
    display: flex;
    justify-content: space-between;
    flex: 1;
}

.wind-temperature-container{
    padding:0 1rem;
    display:flex;
    justify-content:space-between;
}

.wind-tag-container{
    padding:1rem 0;
    width: 48%;
    border:1px solid #57a49f;
    border-radius:5px;
    display:flex;
    justify-content:center;
    background: white;
}

.wind-tag-container-full{
    padding:1rem 0;
    width: 98%;
    border:1px solid #57a49f;
    border-radius:5px;
    display:flex;
    justify-content:center;
    background: white;
}

.wind-tag-container.cold.active{
    background: rgba(198, 234, 251, 0.8);
}

.wind-tag-container-full.cold.active{
    background: rgba(198, 234, 251, 0.8);
}

.wind-tag-container.hot .active{
    display: none;
}

.wind-tag-container.hot.active{
    background: rgba(247, 146, 146, 0.6);
    border-color: #fc5c5c;
}

.wind-tag-container.hot.active .active{
    display: inline-block;
}

.wind-tag-container.hot.active>img{
    display: none;
}

.wind-icon{
    width: 2.2rem;
    height: 2.2rem;
    margin-right: 0.5rem;
}

.wind-level-container{
    padding:0 1rem;
}

.cold-wind-level-selector{
    display: none;
}

.cold-wind-level-selector.active{
    display: block;
}

.hot-wind-level-selector{
    display: none;
}

.hot-wind-level-selector.active{
    display: block;
}

.icon-wrapper {
    position: relative;
    padding: 0.5rem 3rem 0.5rem 2rem;
    border:1px solid #57a49f;
    border-radius:5px;
}

.icon-wrapper .wind-icon {
    position: absolute;
    /* top: -2px; */
}

.icon-wrapper .wind-icon:first-child {
    left: 0;
}

.icon-wrapper .wind-icon:last-child {
    top: -1.2rem;
    bottom: 0;
    right: 0.4rem;
    width: 2.4rem;
    vertical-align: middle;
    margin: auto 0;
}

.wind-types-container{
    padding: 0 1rem;
}

.wind-types-selector{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.wind-type-container{
    position: relative;
    box-sizing: border-box;
    width: 20%;
    padding: 1rem;
    margin: 1rem 6%;
    flex-grow: 1;
    border: 1px dashed green;
    border-radius: 5px;
}

.wind-type-container.active{
    background: rgba(198, 234, 251, 0.8);
}

.wind-type-icon-container{
    padding: 0 1.65rem 1rem;
}

.wind-type-container .wind-type-icon{
    width: 100%;
}

.wind-type-icon.invisible{
    visibility: hidden;
}

.wind-type-container .wind-type-text{
    text-align: center;
}


.justify-fix{
    width: 33%;
}

.temperature-container{
    padding: 0 1rem;
    background: #f8f8f8;
}

.show-time-tag{
    position: absolute;
    font-size: 1.8rem;
    text-align: center;
    color: #1296db;
    left: 0;
    right: 0;
    top: 1rem;
}
.temperature-selector{
    margin: 2rem 3rem;
}
.user_select_disable{
    -webkit-touch-callout:none;  /*系统默认菜单被禁用*/
    -webkit-user-select:none; /*webkit浏览器*/
    -khtml-user-select:none; /*早期浏览器*/
    -moz-user-select:none;/*火狐*/
    -ms-user-select:none; /*IE10*/
    user-select:none;
}
