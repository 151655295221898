.am-card-header {
    display: flex;
    align-items: center;
    font-size: 15px;
    padding: 6px 15px;
}
.am-card-body {
    position: relative;
    border-top: 1PX solid #ddd;
    padding: 10px 6px 6px;
    font-size: 15px;
    color: #333;
    min-height: 40px;
    flex: 1 1;
}
.user_select_disable{
    -webkit-touch-callout:none;  /*系统默认菜单被禁用*/
    -webkit-user-select:none; /*webkit浏览器*/
    -khtml-user-select:none; /*早期浏览器*/
    -moz-user-select:none;/*火狐*/
    -ms-user-select:none; /*IE10*/
    user-select:none;
}

.am-card-header-content{
    flex: 0.5;
}